// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-career-details-js": () => import("./../../../src/pages/career/details.js" /* webpackChunkName: "component---src-pages-career-details-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-case-studies-products-class-management-js": () => import("./../../../src/pages/caseStudies/products/classManagement.js" /* webpackChunkName: "component---src-pages-case-studies-products-class-management-js" */),
  "component---src-pages-case-studies-products-donation-campaign-management-js": () => import("./../../../src/pages/caseStudies/products/donationCampaignManagement.js" /* webpackChunkName: "component---src-pages-case-studies-products-donation-campaign-management-js" */),
  "component---src-pages-case-studies-products-m-d-365-bulk-stock-distribution-js": () => import("./../../../src/pages/caseStudies/products/mD365BulkStockDistribution.js" /* webpackChunkName: "component---src-pages-case-studies-products-m-d-365-bulk-stock-distribution-js" */),
  "component---src-pages-case-studies-products-mic-d-365-xstak-integration-js": () => import("./../../../src/pages/caseStudies/products/micD365XstakIntegration.js" /* webpackChunkName: "component---src-pages-case-studies-products-mic-d-365-xstak-integration-js" */),
  "component---src-pages-case-studies-products-visioedge-js": () => import("./../../../src/pages/caseStudies/products/visioedge.js" /* webpackChunkName: "component---src-pages-case-studies-products-visioedge-js" */),
  "component---src-pages-case-studies-products-viztos-js": () => import("./../../../src/pages/caseStudies/products/viztos.js" /* webpackChunkName: "component---src-pages-case-studies-products-viztos-js" */),
  "component---src-pages-case-studies-projects-banu-mukhtar-crm-js": () => import("./../../../src/pages/caseStudies/projects/banuMukhtarCRM.js" /* webpackChunkName: "component---src-pages-case-studies-projects-banu-mukhtar-crm-js" */),
  "component---src-pages-case-studies-projects-cakes-bakes-dax-2012-revamp-js": () => import("./../../../src/pages/caseStudies/projects/cakesBakesDAX2012Revamp.js" /* webpackChunkName: "component---src-pages-case-studies-projects-cakes-bakes-dax-2012-revamp-js" */),
  "component---src-pages-case-studies-projects-mia-group-js": () => import("./../../../src/pages/caseStudies/projects/miaGroup.js" /* webpackChunkName: "component---src-pages-case-studies-projects-mia-group-js" */),
  "component---src-pages-case-studies-projects-muscat-mghr-solution-js": () => import("./../../../src/pages/caseStudies/projects/muscatMGHRSolution.js" /* webpackChunkName: "component---src-pages-case-studies-projects-muscat-mghr-solution-js" */),
  "component---src-pages-case-studies-projects-orr-js": () => import("./../../../src/pages/caseStudies/projects/ORR.js" /* webpackChunkName: "component---src-pages-case-studies-projects-orr-js" */),
  "component---src-pages-case-studies-projects-s-ohar-portand-fc-portal-js": () => import("./../../../src/pages/caseStudies/projects/sOHARPortandFCPortal.js" /* webpackChunkName: "component---src-pages-case-studies-projects-s-ohar-portand-fc-portal-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dynamics-365-finance-operations-js": () => import("./../../../src/pages/dynamics365/financeOperations.js" /* webpackChunkName: "component---src-pages-dynamics-365-finance-operations-js" */),
  "component---src-pages-dynamics-365-integration-js": () => import("./../../../src/pages/dynamics365/integration.js" /* webpackChunkName: "component---src-pages-dynamics-365-integration-js" */),
  "component---src-pages-dynamics-365-optimization-js": () => import("./../../../src/pages/dynamics365/optimization.js" /* webpackChunkName: "component---src-pages-dynamics-365-optimization-js" */),
  "component---src-pages-dynamics-365-support-js": () => import("./../../../src/pages/dynamics365/support.js" /* webpackChunkName: "component---src-pages-dynamics-365-support-js" */),
  "component---src-pages-dynamics-365-upgrade-js": () => import("./../../../src/pages/dynamics365/upgrade.js" /* webpackChunkName: "component---src-pages-dynamics-365-upgrade-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-at-visiomate-js": () => import("./../../../src/pages/lifeAtVisiomate.js" /* webpackChunkName: "component---src-pages-life-at-visiomate-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/NEWS.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-odoo-implementation-partner-js": () => import("./../../../src/pages/Odoo/implementationPartner.js" /* webpackChunkName: "component---src-pages-odoo-implementation-partner-js" */),
  "component---src-pages-odoo-integration-js": () => import("./../../../src/pages/Odoo/integration.js" /* webpackChunkName: "component---src-pages-odoo-integration-js" */),
  "component---src-pages-odoo-optimization-js": () => import("./../../../src/pages/Odoo/optimization.js" /* webpackChunkName: "component---src-pages-odoo-optimization-js" */),
  "component---src-pages-odoo-support-js": () => import("./../../../src/pages/Odoo/support.js" /* webpackChunkName: "component---src-pages-odoo-support-js" */),
  "component---src-pages-odoo-upgrade-js": () => import("./../../../src/pages/Odoo/upgrade.js" /* webpackChunkName: "component---src-pages-odoo-upgrade-js" */),
  "component---src-pages-services-mobiledevelopment-js": () => import("./../../../src/pages/services/mobiledevelopment.js" /* webpackChunkName: "component---src-pages-services-mobiledevelopment-js" */),
  "component---src-pages-services-softwaretesting-js": () => import("./../../../src/pages/services/softwaretesting.js" /* webpackChunkName: "component---src-pages-services-softwaretesting-js" */),
  "component---src-pages-services-staff-augmentation-lets-discuss-js": () => import("./../../../src/pages/services/staffAugmentationLetsDiscuss.js" /* webpackChunkName: "component---src-pages-services-staff-augmentation-lets-discuss-js" */),
  "component---src-pages-services-staffaugmentation-js": () => import("./../../../src/pages/services/staffaugmentation.js" /* webpackChunkName: "component---src-pages-services-staffaugmentation-js" */),
  "component---src-pages-services-webdevelopment-js": () => import("./../../../src/pages/services/webdevelopment.js" /* webpackChunkName: "component---src-pages-services-webdevelopment-js" */),
  "component---src-pages-services-wordpress-website-developement-js": () => import("./../../../src/pages/services/wordpress_website_developement.js" /* webpackChunkName: "component---src-pages-services-wordpress-website-developement-js" */),
  "component---src-pages-whitepapere-books-js": () => import("./../../../src/pages/WhitepapereBooks.js" /* webpackChunkName: "component---src-pages-whitepapere-books-js" */)
}

